<template>
  <v-col class="mt-1">
    <v-col class="shades transparent pa-6">
      <v-row class="px-0">
        <v-col cols="12" md="12">
          <h2 class="font-weight-bold headline hidden-sm-and-down">등록하신 광고의<br />기본 사항입니다.</h2>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="body-1 font-weight-bold mt-6 mb-2">가맹점코드</div>
              <v-text-field
                :value="adsData.code"
                name="code"
                type="text"
                :rules="[errors.first('code') || !errors.has('code')]"
                hide-details
                single-line
                outlined
                solo-inverted
                flat
                readonly
                required></v-text-field>
            </v-col>
            <v-col class="pt-4 text-right">
              <v-btn color="rouge" class="mt-10" outlined style="width: 140px" @click="showDialog = true"
                >코드변경</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col md="5">
          <div class="body-1 font-weight-bold mt-6 mb-2">광고 범위</div>
          <v-btn-toggle :value="(adsData.category || {}).firstName" mandatory>
            <v-btn
              value="매장"
              active-class="font-weight-bold v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              disabled
              >매장</v-btn
            >
            <v-btn
              value="제품"
              active-class="font-weight-bold v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              disabled
              >제품</v-btn
            >
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" md="8">
          <div class="body-1 font-weight-bold mt-6 mb-2">체험단 종류</div>
          <div style="height: 180px">
            <img :width="$vuetify.breakpoint.mdAndUp ? 160 : 136" height="180" :src="membersTypeImage" />
          </div>
          <!-- <v-btn-toggle :value="adsData.type || 'S'" mandatory> -->
          <!-- <v-btn value="S" active-class="font-weight-bold v-btn--active" class="ma-0" style="width: 130px" outlined disabled>표준 체험단</v-btn> -->
          <!-- <v-btn value="A" active-class="font-weight-bold v-btn--active" class="ma-0" style="width: 130px" outlined disabled>프리미엄 체험단</v-btn> -->
          <!-- </v-btn-toggle> -->
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="body-1 font-weight-bold mt-6 mb-2">분야</div>
              <v-text-field
                :value="(adsData.category || {}).secondName"
                name="secondName"
                type="text"
                :rules="[errors.first('secondName') || !errors.has('secondName')]"
                hide-details
                single-line
                outlined
                solo-inverted
                flat
                readonly
                required></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="body-1 font-weight-bold mt-6 mb-2">세부 유형</div>
              <v-text-field
                :value="(adsData.category || {}).thirdName"
                name="thirdName"
                type="text"
                :rules="[errors.first('thirdName') || !errors.has('thirdName')]"
                hide-details
                single-line
                outlined
                solo-inverted
                flat
                readonly
                required></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="showDialog" content-class="white-two" persistent max-width="500px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="showDialog = false">close</v-icon><br />
                <h2 class="mt-2 font-weight-bold headline">가맹점 코드 변경</h2>
              </div>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-row>
                <v-col cols="12">
                  <div class="body-1 mt-0 mb-6">
                    매장 이용확인시 필요한 가맹점 코드를<br />
                    숫자 두 자리 형식으로 입력해주세요.
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    v-model="form.code"
                    name="coded"
                    type="text"
                    v-validate="'required'"
                    :rules="[errors.first('coded') || !errors.has('coded')]"
                    placeholder="가맹점 코드"
                    hide-details
                    single-line
                    outlined
                    solo
                    flat
                    required></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="rouge"
                outlined
                @click.native="
                  showDialog = false;
                  form.code = adsData.code;
                "
                >취소</v-btn
              >
              <v-btn
                class="rouge white-two--text"
                @click="
                  showDialog = false;
                  save();
                "
                >저장</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Page1',
  mixins: [userMixin],
  props: ['adsData'],
  data: function () {
    return {
      form: {
        code: this.adsData.code,
      },
      showDialog: false,
    };
  },
  watch: {
    adsData(value) {
      this.form = {
        code: value.code || '',
      };
    },
  },

  computed: {
    membersTypeImage() {
      const isMobile = this.$vuetify.breakpoint.mdAndDown;
      const category = (this.adsData.category || {}).firstName;
      const type = this.adsData.type;

      let img = '';

      const extension = (isMobile ? '_mobile' : '') + '.png';

      if (category === '매장') {
        if (type === 'S') {
          img = require('@/assets/img/members-type/standard_active' + extension);
        } else {
          img = require('@/assets/img/members-type/premium_active' + extension);
        }
      } else {
        if (type === 'S') {
          img = require('@/assets/img/members-type/product_express_active' + extension);
        } else {
          img = require('@/assets/img/members-type/product_standard_active' + extension);
        }
      }

      return img;
    },
  },
  methods: {
    save() {
      this.$validator.validateAll().then(success => {
        if (success) {
          this.axios
            .put('/company3?id=' + (this.adsData.id || this.adsData.rKey), {
              ...this.form,
              delay: true,
            })
            .then(() => {
              this.$store.dispatch('getCompanyInfo', {
                email: this.user.email,
              });
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-text-field--outlined.v-text-field--single-line input {
  margin-top: 0;
}

::v-deep .v-text-field--outlined.v-text-field--single-line .v-input__slot {
  min-height: 40px;
  border-radius: 4px;
}
</style>
